import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from './../../../app/helpers/authentication.service';


@Pipe({
  name: 'systemDateTimeFormat'

})
export class systemDateTimeFormatPipe implements PipeTransform {

  constructor(private _authService: AuthenticationService) {}

  // transform(value: any): string {
  //   if (!value) return '';
  //   const userLocale = navigator.language  || 'en-US'; // Get the user's system locale
  //   const options: Intl.DateTimeFormatOptions = {
  //     year: 'numeric',
  //     month: '2-digit',  // 'MMM' for abbreviated month
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     // hour12: false // Use system locale preference
  //   };

  //   return new Intl.DateTimeFormat(userLocale, options).format(new Date(value));

  transform(value: any): string {
    if (!value) {
      return '';
    }

    // Get both date and time formats from the service
    const dateFormat = this._authService.getDateFormat();
    const timeFormat = this._authService.getTimeFormat();

    return this.formatDateTime(value, dateFormat, timeFormat);
  }

  formatDateTime(value: any, dateFormat: string, timeFormat: string): string {
    const date = new Date(value);

    let formattedDate = this.formatDate(date, dateFormat);
    let formattedTime = timeFormat ? this.formatTime(date, timeFormat) : '';

    // Combine date and time, or return date only if time format is not provided
    return `${formattedDate} ${formattedTime}`.trim();
  }

  // Helper function to format date
  formatDate(date: Date, format: string): string {
    const day = String(date.getDate()).padStart(2, '0'); // Ensures two digits for day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures two digits for month
    const year = date.getFullYear(); // Full year (e.g., 2024)
    const shortYear = year.toString().slice(-2); // Last two digits of the year (e.g., 24)

    if (format === 'MM/DD/YYYY') {
        return `${month}/${day}/${year}`;
    } else if (format === 'MM/DD/YY') {
        return `${month}/${day}/${shortYear}`;
    } else if (format === 'DD/MM/YYYY') {
        return `${day}/${month}/${year}`;
    } else if (format === 'DD/MM/YY') {
        return `${day}/${month}/${shortYear}`;
    } else if (format === 'YYYY/MM/DD') {
        return `${year}/${month}/${day}`;
    }

    return '';
  }

  // Helper function to format time
  formatTime(date: Date, format: string): string {
    if (format === 'h:mm a') {
      return this.format12HourTime(date);
    } else if (format === 'HH:mm') {
      return this.format24HourTime(date);
    }
    return '';
  }

  // Helper function to format in 12-hour time (h:mm a)
  format12HourTime(date: Date): string {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    return `${hours}:${minutes} ${ampm}`;
  }

  // Helper function to format in 24-hour time (HH:mm)
  format24HourTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

}


// Display the second in the time format (detection detail - detection log table)
@Pipe({
  name: 'systemDateTimeWithSecondsFormat',
})


export class systemDateTimeWithSecondsFormatPipe implements PipeTransform {
  constructor(private _authService: AuthenticationService) {}

  transform(value: any): string {
    if (!value) {
      return '';
    }

    // Get both date and time formats from the service
    const dateFormat = this._authService.getDateFormat();
    const timeFormat = this._authService.getTimeFormat();

    return this.formatDateTime(value, dateFormat, timeFormat);
  }

  private formatDateTime(value: any, dateFormat: string, timeFormat: string): string {
    const date = new Date(value);

    const formattedDate = this.formatDate(date, dateFormat);
    const formattedTime = timeFormat ? this.formatTime(date, timeFormat) : '';

    // Combine date and time, or return date only if time format is not provided
    return `${formattedDate} ${formattedTime}`.trim();
  }

  // Helper function to format date
  private formatDate(date: Date, format: string): string {
    const day = String(date.getDate()).padStart(2, '0'); // Ensures two digits for day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures two digits for month
    const year = date.getFullYear(); // Full year (e.g., 2024)
    const shortYear = year.toString().slice(-2); // Last two digits of the year (e.g., 24)

    switch (format) {
      case 'MM/DD/YYYY':
        return `${month}/${day}/${year}`;
      case 'MM/DD/YY':
        return `${month}/${day}/${shortYear}`;
      case 'DD/MM/YYYY':
        return `${day}/${month}/${year}`;
      case 'DD/MM/YY':
        return `${day}/${month}/${shortYear}`;
      case 'YYYY/MM/DD':
        return `${year}/${month}/${day}`;
      default:
        return '';
    }
  }

  // Helper function to format time
  private formatTime(date: Date, format: string): string {
    switch (format) {
      case 'h:mm a':
        return this.format12HourTime(date);
      case 'HH:mm':
        return this.format24HourTime(date);
      default:
        return '';
    }
  }

  // Helper function to format in 12-hour time with seconds (h:mm:ss a)
  private format12HourTime(date: Date): string {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  }

  // Helper function to format in 24-hour time with seconds (HH:mm:ss)
  private format24HourTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }
}