import { environment } from "../environments/environment";
import {MyVersion} from "src/my-version";
import { X } from "src/x";
import { Y } from "src/y";
import { Z } from "src/z";
// 10.190.106.205
//console.log("Test Version >>> ",MyVersion.number);
const paginatorQuery = "?page=0&size=10";

export const apiData: any = {
    "version": `${X.x}.${Y.y}.${Z.z}.${MyVersion.number}`,
    "currentBuild": MyVersion.number,
    "url": environment.url.siteUrl,
    "loginUrl": environment.url.siteUrl,
    "serverUrl": environment.url.notificationUrl,
    // "jenkinsBuildNoUrl": environment.url.jenkinsBuildNumUrl,
    "thermalDetect": "thermal/device?isAllDataPoint=",
    "sensorWake" : "thermal/device/activationcount/",
    "timeZoneId" : "timeZoneId",
    "home" : {
        "accountOverview": "dashboard/accountOverview",
        "sensorSummary": "customers/sensorSummary",
        "pestDetections": "dashboard/pestDetections",
        // "jobs": "dashboard/jobs",
        "activityTrendBarGraph": "dashboard/activityTrendGraph/",
        "activityTrendLineGraph": "dashboard/trendline/",
        "notificationCenter": "notifications/details",
        "notificationClearAll" : "notifications/clearAll",
        "notificationCount": "notifications/count",
        "importantNotificationEventOptions" : "impnotifications/options",
        "notificationCenterEventOptions": "notifications/options",
        "liveMonitoring" : "zone/liveMonitoring"
    },
    "user": {
        "paginationUrl": "user?page=0&size=10",
        "defaultUrl": "user",
        "userImageUrl": "user/image",
        "updateUserImageUrl": "user/userdetails/image",
        "userIsExists": "user/username/isexists",
        "enableOrDisable": "user/enableordisable",
        "getFieldTechId": "6",
        "noPagination": "user?pagetype=false",
        "userManagerRoleId": "user/manager?pageType=false",
        "userDetails": "user/userdetails",
        "userList" : "users/list",
        "directReportees" : "user/directReportees/list",
        "reportingManagers" : "user/enableordisable/reportingManagers"
    },
    "role": {
        "defaultRoles": "role?roleType=default",
        "enableOrDisable": "role/enableordisable",
        "deleteRole": "role/"
    },
    "oldUser": "user/",
    "login": "user/login",
    "logout": "user/logout/",
    "topic": "machine.data",
    "loginForgot": "user/forgot/password",
    "loginResetPassword": "",
    "webLogin": "/?isWebLoggedIn=true",
    "oAuth": "oauth/token",
    "socketuser": environment.url.userName,
    "socketPassword": environment.url.password,
    "UASTokenUrl": environment.url.oAuth,
    "roleList": "role",
    "roleListPageFlase": "role?pageType=false",
    "roleActive": "role/active",
    "roleCreate": "role/copy/create",
    "roleIsExists": "role/rolename/isexists",
    "moduleList": "module",
    "customers": {
        "paginationUrl": "customers" + paginatorQuery + "&sort=lastModifiedDate,desc",
        "defaultUrl": "customers",
        "singleCustomerUrl": "customer",
        // "resourceUrl": "resource/count/customer",
        // "flyAlertCount": "fly/alert/customer",
        // "crawlAlertCount": "crawl/alert/customer",
        // "chart": "flyorcrawl/count/report/day",
        // "breached": "activity/breached/",
        "boneCustomerList" : "customers?sort=lastModifiedDate,Desc&pageType=false",
        "customerNameIsExits": "customers/customername/isexists"
    },
    "sites": {
        "paginationUrl": "sites/56" + paginatorQuery + "&sort=siteName,asc",
        "defaultUrl": "siteList",
        "singleSiteUrl": "site",
        // "resourceUrl": "resource/count/site",
        // "flyAlertCount": "fly/alert/site",
        // "crawlAlertCount": "crawl/alert/site",
        "siteTypeList": "site/type/details",
        "updateBreachLimit": "site/type",
        "siteNameIsExits": "sites/sitename/isexists",
        "categories": "activity/categories",
        "categoriesFilter": "activity/categories?filter=all",
        "detectionTableList": "activities/list",
        "sensorTypes": "sensortypes",
        "sensorsStatus": "sensor/statusLabels",
        "sensorFilter": "sensors/filter",
        "addSiteZones": "sitewithzones",
        "zoneTooltipBreach": "breach/zone",
        "siteTooltipBreach": "breach/site",
        "serviceTeam": "site/serviceteam/reportingusers",
        "serviceList": "site/serviceteam/list",
        "addUserList": "site/serviceteam/adduser",
        "revokeAccessUserList": "site/serviceteam/revokeaccess",
        "addUser": "site/serviceteam/adduser",
        "revokeAccess": "site/serviceteam/revokeaccess",
        "eventsLog" : "sitelog/details",
        "eventOptions" : "siteevent/options"
    },
    "zones": {
        "paginationUrl": "zones/60" + paginatorQuery + "&sort=zoneName,asc",
        "defaultUrl": "zones",
        "singleZoneUrl": "zone",
        
        // "resourceUrl": "resource/count/zone",
        // "flyAlertCount": "fly/alert/zone",
        // "crawlAlertCount": "crawl/alert/zone",
        "zoneNameIsExits": "zones/zonename/isexists",
        "zoneImage": "zone/image/"
    },
    "sensors": {
        "paginationUrl": "sensors" + paginatorQuery, // + "&sort=lastModifiedDate,Desc",
		"sensorsList":  "sensors",
        "defaultUrl": "sensors?sort=lastModifiedDate,Desc",
        "sensor": "sensor", // "/<sensorID>"
        "singleZoneUrl": "sensor",
        "sensorTypes": "sensortypes",
        "sensorsStatus": "sensor/statusLabels",
        "sensorImage": "sensor/image",
        "sensorMetaData": "sensors/metadata",
        "sensorMetaDataSingle": "sensor/metadata",
        "sensorFilter": "sensors/filter",
        "filterPainationUrl": "sensors/filter" + paginatorQuery,
        "sensorReport": "sensors/list",
        "getSensorInternalId": "sensor/sensorId/",
        "mapping": "sensor/zone/mapping",
        "alerts": "crawlorfly/alert/sensor/",
        "activities": "activity/count/sensor/",
        "sensorTypeId":"sensors/sensortypeid/",
        "directional": "report/crawl/directional/",
        "sensorNameIsExits": "sensors/sensorname/isexists",
        "glueboardEventLog": "glueboardlogs/",
        "replaceSensor": "sensor/replace",
        "sensorEventLogUrl": "sensorlog/details",
        "sensorEventOptions": "sensorevent/options",
        "detectionlogs": "sensor/detectionlogs",
        "findbatterylife" : "findbatterylife",
        "createdFullName" : "sensors/createdFullName"
    },
    "resourceHierarchy": "resource/hierarchy",
    "changePassword": "user/reset/password",
    "detections": {
        "tableList": "activities/list"
    },
    "activity": {
        "paginationUrl": "activities" + paginatorQuery,
        "activities": "activities",
        "activity": "activity",
        "activityCount": "activity/count",
        "categories": "activity/categories",
        "assign": "activity/assignto",
        "perform": "activity/perform",
        "statusList": "activity/status/list",
        "eventActivity": "events/activity",
        "upload":"bulk/upload/devices",
        "eventList": "detectionlogs",
        "resolve": "activity/resolve",
        "resolutionDetail":"activity/resolution/details",
        "zoneResolve": "activity/resolveByZone",
        "siteResolve": "activity/resolveBySite"
    },
    "report": {
        "crawl": "crawl/count/report",
        "fly": "fly/count/report",
        "cigaretteBeetle" : "cigaretteBeetle/count/report",
        "allClientComparisonCrawl": "crawl/clients/comparison/count/report",
        "allClientComparisonFly": "fly/clients/comparison/count/report",
        "individualClientComparisonCrawl": "crawl/individual/client/sites/comparison/count/report",
        "individualClientComparisonFly": "fly/individual/client/sites/comparison/count/report",
        "pestInfestation": "clientsorseletedclient/comparison/count/species/report",
        "hygieneReport": "hygiene/analysis/comparison/count/report",
        "pestActivity": "pestactivity/comparison/count/species/report",
        "dumbellCrawl": "crawl/dumble/report",
        "dumbellFly": "fly/dumble/report",
        "dumbellCigaretteBeetle" : "cigaretteBeetle/dumble/report",
        "directional": "report/crawl/directional/chart",
        "aqi": "report/aqi/event/lasthour",
        "flyCountJ": "daily/fly/count",
        "flyCountTableData":"fly/sensor/glueboard/count",
        "siteCumulative" : "report/sitePestDetections/cumulative/hours",
        "stakeholder" : "stakeholder/report/",
        "sensorValidationTestList" : "report/sensorValidationTest/list",
        "sensorValidationTest" : "report/sensorValidationTest"
    },
    "map": {
        "sites": "map/sites",
        "alertCrawl": "map/crawl/alert/",
        "alertFly": "map/fly/alert/",
        "sensorList": "map/sensorstatus/site",
        "chart": "flyorcrawl/count/report/day",
        "donutChart": "activity/count/",
        "siteDetail": "site/",
        "activitiesPendingCount": "activity/count/site",
        "installationCounts": "activity/count/site",
        "overviewAllAlerts": "map/all/alert/sites",
        "allAlerts": "map/all/alert",
    },
    "alerts": {
        "flyAlert": "fly/alert",
        "crawlAlert": "crawl/alert",
    },
    "calendar": {
        // "categories": "activity/categories",
        // "events": "calendar/activities"
    },
    "languageList": "language/details",
    "settingDetails": "setting/details",
    "updateLanguageSetting": "setting/1",
    "settings": "settings",
    "settingTypeId" : "settings/settingtypeid",
    "updateSetting" : "setting/",
    "breachLimits": {
      "gluboard" : "setting/glueboardbreachlimit",
      "cigaretteBeetleGlueboard" : "setting/cigarettebeetleglueboardlimit",
      "zone" : "setting/zonebreachlimit"
    }
};
export const pestTypeName: any = [
    "rat",
    "mouse",
    "cockroach",
    "adult bed bug",
    "instar bed bug",
    "house fly",
    "bottle fly",
    "fly trap",
    "sensit",
    "rodent killer",
    "rodent bait box",
    "mouse trap"];
export const crawlPest: any = [
    "rat",
    "mouse",
    "cockroach",
    "adult bed bug",
    "instar bed bug",
    "rodent killer",
    "rodent bait box",
    "mouse trap"];
export const flyPest: any = ["house fly", "bottle fly", "fly trap"];

// "url": "http://10.190.106.93:8080/api/v1/",
// "url":"http://ec2-13-126-250-246.ap-south-1.compute.amazonaws.com/brandenburg-iop-anana/api/v1/",
export const images: any = [
    "./assets/images/bugs-pests/icon-rat.svg",
    "./assets/images/bugs-pests/icon-bottle-fly.svg",
    "./assets/images/bugs-pests/icon-mouse.svg",
    "./assets/images/bugs-pests/icon-housefly.svg",
    "./assets/images/bugs-pests/icon-instar-beg-bug.svg",
];
export const pestType: any = {
    "Rodent":
    {
        "img": "./assets/images/bugs-pests/icon-rat.svg",
        "type": "crawl"
    },
    "Mouse":
    {
        "img": "./assets/images/bugs-pests/icon-mouse.svg",
        "type": "crawl"
    },
    "Cockroach":
    {
        "img": "./assets/images/bugs-pests/icon-cockroach.svg",
        "type": "crawl"
    },
    "AdultBedBug":
    {
        "img": "./assets/images/bugs-pests/icon-adult-bed-bug.svg",
        "type": "crawl"
    },
    "InstarBedBug":
    {
        "img": "./assets/images/bugs-pests/icon-instar-beg-bug.svg",
        "type": "crawl"
    },
    "HouseFly":
    {
        "img": "./assets/images/bugs-pests/icon-housefly.svg",
        "type": "fly"
    },
    "BottleFly":
    {
        "img": "./assets/images/bugs-pests/icon-bottle-fly.svg",
        "type": "fly"
    },
    "Rat":
    {
        "img": "./assets/images/bugs-pests/icon-rat.svg", "type":
            "crawl"
    },
    "RodentKiller":
    {
        "img": "./assets/images/bugs-pests/mouseZapper.png", "type":
            "crawl"
    },
    "RodentBaitBox":
    {
        "img": "./assets/images/bugs-pests/icon-rat.svg", "type":
            "crawl"
    },
    "MouseTrap":
    {
        "img": "./assets/images/bugs-pests/icon-rat.svg", "type":
            "crawl"
    }
};

export const map: any = {
    "active": "./assets/images/iconSet/LocationPinSingleActive.png",
    "inActive": "./assets/images/iconSet/LocationPinSingleInActive.png"
};
export const activityStatus: any = {
    "Open": "Open",
    "Closed": "Closed",
    "Assigned": "Assigned",
    "Service": "Service Procedure",
    "Action": "Further Action Required",
    "Start": "Started Procedure"
};
// "brandenburg":"./assets/images/brandenburgLogo.png"
// "dubai":"./assets/images/Dubai_Municipality_Logo.png"


// Brandenburg
// export const logo="./assets/images/brandenburgLogo.png";

// Dubai
export const logo = "./assets/images/b-logo.png";

export const cLogo = environment.cLogo;

// export const cLogo= "./assets/images/SpragueNewLogo.png";

// export const iopLogo = "./assets/images/email/iop-logo.png";

export const iopLogo = "./assets/images/IOPLogo-reverse.gif";
export const iopLogoLight = "./assets/images/email/iop-logo.png";

export const circleLogo = "./assets/images/email/blogoCircle.png";

export const Logo = {
    "whiteBrandenburg-Logo": "./assets/images/CompanyLogo/brandenburgLight-v1.png",
    "blueBrandenburg-Logo": "./assets/images/CompanyLogo/brandenburgBlue-v1.png",
    "whiteCalmic-Logo": "./assets/images/CompanyLogo/calmicLight-v1.png",
    "calmic-Logo": "./assets/images/CompanyLogo/calmic-v1.png",
    "darkIop-Logo": "./assets/images/CompanyLogo/iopDark-v1.png",
    "lightIop-Logo": "./assets/images/CompanyLogo/iopWhite-v1.png"
};

export const languages = [{
    name: "English",
    alias: 1
}, {
    name: "Japanese",
    alias: 2
}];

// "url": "http://10.190.106.85:8080/api/v1/",
// "url":"http://ec2-13-126-250-246.ap-south-1.compute.amazonaws.com/brandenburg-iop-anana/api/v1/",
// "url": "http://ec2-13-127-189-59.ap-south-1.compute.amazonaws.com/brandenburg-iop/api/v1/",
// "url": "http://ec2-13-126-80-132.ap-south-1.compute.amazonaws.com:8080//api/v1/",
// "url":"http://ec2-13-127-52-122.ap-south-1.compute.amazonaws.com/brandenburg-iop-anana/api/v1/",

// "serverUrl": "http://ec2-13-127-52-122.ap-south-1.compute.amazonaws.com/brandenburg-iop-anana/iop-websocket",
// "serverUrl": "http://ec2-13-126-80-132.ap-south-1.compute.amazonaws.com:8080/iop-websocket",
// "serverUrl": "http://ec2-13-127-189-59.ap-south-1.compute.amazonaws.com/brandenburg-iop/iop-websocket",
// "serverUrl": "http://ec2-13-126-250-246.ap-south-1.compute.amazonaws.com/brandenburg-iop-anana/iop-websocket",

export const timeZoneApi = {
    url: "https://maps.googleapis.com/maps/api/timezone/json?location=",
    timestamp: "&timestamp=1458000000",
    apiKey: "&key=AIzaSyB8wUAJmDisxTIOJ9-flOST3iy-71Hd1Cg"
}