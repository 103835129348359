import { CommonDataService } from './../../../helpers/services/common-data.service';
import { apiData } from './../../../common';
import { Injectable } from '@angular/core';
import { Observable,throwError  } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UserIsExists } from 'src/app/modules/iop-module/roles/roles.service';

export interface EnableMessage {
  message: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _commonDataService: CommonDataService) { }

  getFielTechData(url, roleId): Observable<viewUser.UserResponse> {
    
    if (roleId) {
      let value = new URLSearchParams();
      value.append('roleId', roleId);
      url += '?' + value.toString();
    }  

    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((userResponse: viewUser.UserResponse) => {
        observer.next(userResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });

  }

  getUserPaginated(url = apiData.url + apiData.user.paginationUrl, roleId): Observable<viewUser.UserResponse> {
    // url = apiData.url + url;
    // url = 'http://dev-uas.iopbeta.com:8080/api/v1/' + url;
    
    if (roleId) {
      let value = new URLSearchParams();
      if (roleId === 'CustomRole') {
        value.append('userType', 'custom');
      } else {
        value.append('roleId', roleId);
      }
      url += '&' + value.toString();
    }
    url += '&sort=userName,asc';
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((userResponse: viewUser.UserResponse) => {
        observer.next(userResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });

  }

  getUserList(payload,url) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payload, url).subscribe((userResponse: Observable<viewUser.UserResponse>) => {
        observer.next(userResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  saveUserWithImage(user: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.delete('content-type');
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(user, apiData.url + apiData.user.userImageUrl, headers).subscribe((userInfo: any) => {
        if (userInfo.status === 'ok') {
          observer.next(userInfo);
          observer.complete();
        } else {
          observer.next(userInfo);
          observer.complete();
        }
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  saveUserWithImageEdit(user: any, userName: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.delete('content-type');
    return new Observable(observer => {
      this._commonDataService.putDataOnlyIOPHeader(user, apiData.url + apiData.user.updateUserImageUrl, headers).subscribe((userInfo: any) => {
        if (userInfo.status === 'ok') {
          observer.next(userInfo);
          observer.complete();
        } else {
          observer.next(userInfo);
          observer.complete();
        }
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getUserDetails(payload) {
    return new Observable(observer => {
      this._commonDataService.postData(payload, apiData.url + apiData.user.userDetails).subscribe((userResponse: viewUser.UserResponse) => {
        observer.next(userResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
  });  
  
    // return new Observable(observer => {
    //   this._commonDataService.getData(apiData.url + apiData.user.defaultUrl + '/' + userName).subscribe((userResponse: viewUser.UserResponse) => {
    //     observer.next(userResponse);
    //     observer.complete();
    //   }, error => {
    //     throw new Error(JSON.stringify(error));
    //   });
    // });
  }

  getResourceHierarchy() {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.resourceHierarchy).subscribe((res: any) => {
        observer.next(res);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  isExists(value) {
    return new Observable(observer => {
      this._commonDataService.postData(value, apiData.url + apiData.user.userIsExists).subscribe((roleResponse: UserIsExists) => {
        observer.next(roleResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
    // return new Observable(observer => {
    //   this._commonDataService.getData(apiData.url + apiData.user.userIsExists + '/' + name).subscribe((roleResponse: UserIsExists) => {
    //     observer.next(roleResponse);
    //     observer.complete();
    //   }, error => {
    //     throw new Error(JSON.stringify(error));
    //   });
    // });
  }

  isExistsUpdate(name: string, username) {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.user.userIsExists + '/' + name + '?userId=' + username).subscribe((roleResponse: UserIsExists) => {
        observer.next(roleResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }
  
  enableDisable(value): Observable<EnableMessage> {
    return new Observable(observer => {
      this._commonDataService.postData(value, apiData.url + apiData.user.enableOrDisable).subscribe((response: EnableMessage) => {
        observer.next(response);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }
}
