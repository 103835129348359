import { Injectable, EventEmitter } from '@angular/core';
import { theme } from './../../classModel/themes';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeWrapper = document.querySelector('body');
  public themeChange = new EventEmitter<any>();
  constructor() {
  }
  
  underGoChartThemeChange(themeColor, chart) {
    const c = chart;
    if (chart) {
      try {
        if (chart.chart.backgroundColor) {
          chart.chart.backgroundColor = theme[themeColor].bgColor;
        } else {
          // console.log('no background');
        }
        chart.xAxis.lineColor = theme[themeColor].textColor;
        chart.xAxis.title.style.color = theme[themeColor].textColor;
        chart.xAxis.labels.style.color = theme[themeColor].textColor;
        // Y Axis
        if (chart.yAxis.length > 0) {
          chart.yAxis[0].labels.style.color = theme[themeColor].textColor;
          chart.yAxis[0].title.style.color = theme[themeColor].textColor;
          chart.yAxis[1].labels.style.color = theme[themeColor].textColor;
          chart.yAxis[1].title.style.color = theme[themeColor].textColor;
          chart.yAxis[0].gridLineColor = theme[themeColor].textColor;
          chart.yAxis[0].lineColor = theme[themeColor].textColor;
        } else {
          chart.yAxis.labels.style.color = theme[themeColor].textColor;
          chart.yAxis.title.style.color = theme[themeColor].textColor;
          chart.yAxisgridLineColor = theme[themeColor].textColor;
          chart.yAxislineColor = theme[themeColor].textColor;
          chart.yAxis.gridLineColor = theme[themeColor].textColor;
          chart.yAxis.lineColor = theme[themeColor].textColor;
        }
        // legend
        // chart.legend.itemHoverStyle.color = theme[themeColor].textColor;
        // chart.legend.itemStyle.color = theme[themeColor].textColor;
        if (chart.colorAxis) {
          chart.colorAxis.labels.style.color = theme[themeColor].textColor;
        }
        if (chart.legend) {
          chart.legend.itemHoverStyle.color = theme[themeColor].textColor;
          chart.legend.itemStyle.color = theme[themeColor].textColor;
        }
        if (chart.plotOptions) {
          if (chart.plotOptions.column) {
            if (chart.plotOptions.column.dataLabels.color) {
              chart.plotOptions.column.dataLabels.color = theme[themeColor].textColor;
            }
          }
        }
        return chart;
      } catch (e) {
        console.log('Theme Change errored In Charts Returning Unmodified');
        console.log(e.stack);
        return c;
      }
    }
  }
  
  changeTheme(themeClass: string) {
    //console.log("themeclass>>", themeClass)
    // themeClass = themeClass.toLowerCase();
    this.themeWrapper.style.setProperty('--bg-color', theme[themeClass].bgColor);
    this.themeWrapper.style.setProperty('--box-color', theme[themeClass].boxColor);
    this.themeWrapper.style.setProperty('--light-text-color', theme[themeClass].lightTextColor);
    this.themeWrapper.style.setProperty('--fore-color', theme[themeClass].foreColor);
    this.themeWrapper.style.setProperty('--focus-color', theme[themeClass].focusColor);
    this.themeWrapper.style.setProperty('--text-color', theme[themeClass].textColor);
    this.themeWrapper.style.setProperty('--secondary-color', theme[themeClass].secondaryColor);
    this.themeWrapper.style.setProperty('--secondary-text-color', theme[themeClass].secondaryTextColor);
    this.themeWrapper.style.setProperty('--secondary-variant', theme[themeClass].secondaryVariant);
    this.themeWrapper.style.setProperty('--input-field-border', theme[themeClass].inputFieldBorder);
    this.themeWrapper.style.setProperty('--hover-color', theme[themeClass].hoverColor);
    this.themeWrapper.style.setProperty('--secondary-hover-color', theme[themeClass].secondaryHoverColor);
    this.themeWrapper.style.setProperty('--multi-select-title-bg', theme[themeClass].multiselectTitleBg);
    this.themeWrapper.style.setProperty('--cockroach-color', theme[themeClass].cockroachColor);
    this.themeWrapper.style.setProperty('--cigarette-beetle-color', theme[themeClass].cigaretteBeetleColor);
    this.themeWrapper.style.setProperty('--fly-color', theme[themeClass].flyColor);
    this.themeWrapper.style.setProperty('--rodent-color', theme[themeClass].rodentColor);
    this.themeWrapper.style.setProperty('--new-detection', theme[themeClass].newDetection);
    this.themeWrapper.style.setProperty('--resolved-detection', theme[themeClass].resolvedDetection);
    this.themeWrapper.style.setProperty('--tab-header', theme[themeClass].tabHeader);

    this.themeWrapper.style.setProperty('--shade1', theme[themeClass].shade1);
    this.themeWrapper.style.setProperty('--shade2', theme[themeClass].shade2);
    this.themeWrapper.style.setProperty('--shade3', theme[themeClass].shade3);
    this.themeWrapper.style.setProperty('--shade4', theme[themeClass].shade4);
    this.themeWrapper.style.setProperty('--shade5', theme[themeClass].shade5);
    this.themeWrapper.style.setProperty('--shade6', theme[themeClass].shade6);
    this.themeWrapper.style.setProperty('--shade7', theme[themeClass].shade7);
    this.themeWrapper.style.setProperty('--shade8', theme[themeClass].shade8);
    this.themeWrapper.className = '';
    this.themeWrapper.className = themeClass;
    this.themeChange.emit(themeClass);
  }
}