export const theme = {
    
    'calmic': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgLight.png',
        'bgColor': '#323b44',
        'foreColor': '#36404a',
        'textColor': 'whitesmoke',
        'hoverColor': '#07799f',
        'secondaryHoverColor': '#7FBBCC',
        'secondaryColor': '',
        'secondaryTextColor': 'whitesmoke',
        'shade1': '#009fd3',
        'shade2': '#64b1dd',
        'shade3': '#94c5e6',
        'shade4': '#bcd8ef',
        'shade5': '#dfecf8',
        'gridOpaque': 'rgba(255,255,255,0.2)',
        'map': [
            {
                'featureType': 'administrative',
                'elementType': 'labels',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#303030'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#FFFFFF'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#808080'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#303030'
                    }
                ]
            }
        ]
    },
    'gray': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgLight.png',
        'bgColor': '#74708D',
        'foreColor': '#67637e',
        'textColor': '#fff',
        'hoverColor': '#585471',
        'secondaryHoverColor': '#E4E4EF',
        'extra': '#DC2B34',
        'secondaryColor': '',
        'secondaryTextColor': 'whitesmoke',
        'shade1': '#E2595D', // Error
        'shade2': '#009A6F', // Ok   
        'shade3': '#EE8240', // Warning
        'shade4': '#67637e', // row
        'shade5': '#928CA5',
        'gridOpaque': 'rgba(0,0,0,0.2)',
        'map': [
            {
                'featureType': 'administrative',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'gamma': '2.63'
                    },
                    {
                        'weight': '0.01'
                    }
                ]
            },
            {
                'featureType': 'administrative',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'color': '#636363'
                    }
                ]
            },
            {
                'featureType': 'landscape',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#74708D'
                    },
                    {
                        'lightness': '0'
                    },
                    {
                        'saturation': '0'
                    },
                    {
                        'gamma': '1.44'
                    },
                    {
                        'weight': '1'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'hue': '#74708D'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'off'
                    },
                    {
                        'color': '#FFFFFF'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'all',
                'stylers': [
                    {
                        'saturation': -100
                    },
                    {
                        'lightness': 45
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'color': '#4e0000'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#fad87c'
                    }
                ]
            },
            {
                'featureType': 'road.arterial',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'transit',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'on'
                    },
                    {
                        'saturation': '33'
                    },
                    {
                        'lightness': '60'
                    },
                    {
                        'gamma': '0.67'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    }
                ]
            }
        ]
    },
    'light': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgBlue.png',
        'bgColor': 'white',
        'foreColor': 'whitesmoke',
        'textColor': 'black',
        'hoverColor': '#07799f',
        'secondaryHoverColor': '#7FBBCC',
        'secondaryColor': '',
        'secondaryTextColor': 'black',
        'shade1': '#E2595D', // Error
        'shade2': '#009A6F', // Ok   
        'shade3': '#EE8240', // Warning
        'shade4': '',
        'shade5': '',
        'gridOpaque': 'rgba(0,0,0,0.2)',
        'map': [
            {
                'featureType': 'water',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#d3d3d3'
                    }
                ]
            },
            {
                'featureType': 'transit',
                'stylers': [
                    {
                        'color': '#808080'
                    },
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'color': '#b3b3b3'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            },
            {
                'featureType': 'road.local',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'color': '#ffffff'
                    },
                    {
                        'weight': 1.8
                    }
                ]
            },
            {
                'featureType': 'road.local',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'color': '#d7d7d7'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'color': '#ebebeb'
                    }
                ]
            },
            {
                'featureType': 'administrative',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#a7a7a7'
                    }
                ]
            },
            {
                'featureType': 'road.arterial',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            },
            {
                'featureType': 'road.arterial',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            },
            {
                'featureType': 'landscape',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'color': '#efefef'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'color': '#696969'
                    }
                ]
            },
            {
                'featureType': 'administrative',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'color': '#737373'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'road.arterial',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'color': '#d6d6d6'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {},
            {
                'featureType': 'poi',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#dadada'
                    }
                ]
            }
        ]
    },
    'calmicRed': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgLight.png',
        'bgColor': '#6d151b',//'rgb(0, 0, 0,0)',
        'foreColor': '#530d10',//'rgba(0,0,0,0.1)',
        'textColor': 'white',
        'hoverColor': '#928CA5',
        'secondaryHoverColor': '',
        'secondaryColor': '',
        //'extra': '#DC2B34',
        'secondaryTextColor': 'whitesmoke',
        'shade1': '#E2595D', // Error
        'shade2': '#009A6F', // Ok   
        'shade3': '#EE8240', // Warning
        'shade4': 'rgb(185,191,211,0.2)',//'#B9BFD3', //'rgba(0,0,0,0.2)'
        'shade5': '#6d151b',
        'gridOpaque': 'rgba(0,0,0,0.2)',
        'map': [
            {
                'featureType': 'administrative',
                'elementType': 'labels',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#303030'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#FFFFFF'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#808080'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#303030'
                    }
                ]
            }
        ]
    },
    'default': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgBlue.png',
        'bgColor': '#F5F6F8',//'#FFFFFF',
        'boxColor': '#FFFFFF',//'#fdfdfd',//'#f5f6f8',
        'color': '',
        'textColor': '#000000',
        'lightTextColor': '#B8B8B8',
        'secondaryColor': '#E5F1F5',
        'secondaryTextColor': '#000000', // details screen- label/header Name color
        'secondaryVariant': '#F7F7F7',
        'inputFieldBorder' : '#DFE1E5',
        'foreColor': '#F5F6FA',    
        'focusColor' : '#DBF0FF',  
        'hoverColor': '#004A81',
        'graphGridLineColor': '#EBEBEB',
        'secondaryHoverColor': '#004A81',  
        'multiselectTitleBg': '#F5F5F5',   
        'cockroachColor' : '#68C1EF',
        'cigaretteBeetleColor' : '#C4BC7C',
        'flyColor' : '#DEA25D',
        'rodentColor' : '#746AE0',  
        'newDetection' : '#475FE2',
        'resolvedDetection': '#36DDB6',
        'tabHeader' : '#525E62',
        'shade1': '#FF0000', // Error
        'shade2': '#86B23F',//'#009A6F', // Ok   
        'shade3': '#FD7622', // Idle 
        'shade4': '#B9BFD3', // table Header Bg     
        'shade5': '#F7F7F7', // table row grey      
        'shade6': '$info-wraning-note',//'#F5F5F5', / breached  
        'shade7': '#B2B2B2', // fly bar color
        'shade8' : '#FDF8E8', // Detection list - row BG hightlight
        'gridOpaque': 'rgba(255,255,255,0.2)',
        'map': [
            {
                'featureType': 'administrative',
                'elementType': 'labels',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#303030'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#FFFFFF'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#808080'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#303030'
                    }
                ]
            }
        ]
    },
    'newTheme': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgBlue.png',
        'bgColor': '#F5F6F8',//'#FFFFFF',
        'boxColor': '#FFFFFF',//'#fdfdfd',
        'color': '',
        'textColor': '#717171',
        'lightTextColor': '#B8B8B8',
        'secondaryColor': '',
        'secondaryTextColor': '#19A1D4', //'#404040',  
        'secondaryVariant': '#F2FAFF',
        'inputFieldBorder' : '#257B9B',
        'foreColor': '#F5F6FA',    
        'hoverColor': '#86B23F',
        'secondaryHoverColor': '#B6CE34',  
        'multiselectTitleBg': '#F5F5F5', 
        'cockroachColor' : '#AE7A4C',
        'flyColor' : '#AC9B5B',
        'rodentColor' : '#796E64',    
        'newDetection' : '#257B9B',
        'resolvedDetection': '#2CC48A', 
        'tabHeader' : '#525E62',
        'shade1': '#FF0000', // Error
        'shade2': '#86B23F',//'#009A6F', // Ok   
        'shade3': '#FD7622', // Idle 
        'shade4': '#B9BFD3', // table Header Bg     
        'shade5': '#EDF2F7', // table row grey      
        'shade6': '$info-wraning-note',//'#F5F5F5', / breached  
        'shade7': '#B2B2B2', // fly bar color
        'shade8' : '#A7AFB7', // table header text color
        'gridOpaque': 'rgba(255,255,255,0.2)',
        'map': [
            {
                'featureType': 'administrative',
                'elementType': 'labels',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#303030'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#FFFFFF'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#808080'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#303030'
                    }
                ]
            }
        ]
    },
    'oldDefault': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgBlue.png',
        'bgColor': '#fff',
        'bobyBgColor': '#f5f6f8',
        'color': '',
        'textColor': '#717171',
        'secondaryColor': '',
        'secondaryTextColor': '#404040', 
        'secondaryVariant': '#F2FAFF',
        'inputFieldBorder' : '#257B9B',
        'foreColor': '#F5F6FA',
        'hoverColor': "#19a1d4",//'#0074AE',
        'secondaryHoverColor': '',
        'shade1': '#E2595D', // Error
        'shade2': '#009A6F', // Ok   
        'shade3': '#EE8240', // Warning
        'shade4': '#B9BFD3', // table Header Bg     
        'shade5': '#F4F5F8', // table row grey      
        'shade6': '#F5F5F5', //        
        'gridOpaque': 'rgba(255,255,255,0.2)',
        'map': [
            {
                'featureType': 'administrative',
                'elementType': 'labels',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#303030'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#FFFFFF'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#808080'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#303030'
                    }
                ]
            }
        ]
    },
    'dark': {
        'b-logo-path': './assets/images/CompanyLogo/brandenburgBlue.png',
        'bgColor': '#454545',
        'bobyBgColor': '#f5f6f8',
        'color': '',
        'textColor': '#000000',
        'secondaryColor': '',
        'secondaryTextColor': '#767676,',
        'secondaryVariant': '#F2FAFF',
        'inputFieldBorder' : '#257B9B',
        'foreColor': '#605f5f',
        'hoverColor': '#e4faff',
        'secondaryHoverColor': '#B6CE34',
        'btnTextColor': '#FFFFFF',
        'shade1': '#e64444',
        'shade2': '#3bdfb1',
        'shade3': '#EE8240',
        'shade4': '#00000029',
        'shade5': '#767676',
        'shade6': '#F5F5F5',
        'shade7': '#B2B2B2', // fly bar color
        'shade8' : '#FCE496', // Detection row highlight bg color
        'gridOpaque': '#F5F5F5',
        'multiselectTitleBg': '#F5F5F5',     
        'cockroachColor' : '#AE7A4C',
        'flyColor' : '#AC9B5B',
        'rodentColor' : '#796E64', 
        'newDetection' : '#257B9B',
        'tabHeader' : '#525E62',
        'resolvedDetection': '#2CC48A',
        'map': [
            {
                'featureType': 'administrative',
                'elementType': 'labels',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#303030'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#FFFFFF'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'simplified'
                    },
                    {
                        'color': '#808080'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'color': '#FFFFFF'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    {
                        'color': '#303030'
                    }
                ]
            }
        ]
    }
};